import { React, useEffect, useCallback, useContext } from "react";
import { useState } from "react";
import {
  Box,
  Text,
  Grid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  CloseButton,
  useBreakpointValue,
  Input,
  Badge,
  Select,
  CircularProgress,
} from "@chakra-ui/react";
import { BsFillPencilFill, BsFillFileCheckFill } from "react-icons/bs";
import "../../../../css/style.css";
import Confirmacion from "./Confirmacion";
import ModalAbonar from "./ModalAbonar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postConfirmar } from "api/controllers/facturas";
import { putMonto } from "api/controllers/facturas";
import { generateUniqueId } from "helpers";
import { useFacturaDetail } from "hooks/Facturas/useFacturaDetail";
import GeneralButton from "components/widgets/Buttons/GeneralButton";
import { useFacturas } from "hooks/Facturas/useFacturas";
import AddAbonarModal from "components/widgets/Modals/AddAbonarModal";
import { postFactura } from "api/controllers/facturas";
import { postRecibo } from "api/controllers/facturas";
import { Separator } from "components/Separator/Separator";
import { postArchivar } from "api/controllers/facturas";
import ModalPrint from "components/widgets/Modals/ModalPrintFact";
import ModalFctTerceros from "components/widgets/Modals/ModalFctTerceros";
import { getStudiesDetail } from "api/controllers/estudios";
import MainContext from "context/mainContext/MainContext";
import { formatDate } from "helpers";
import { putChangeIdOrdenClient } from "api/controllers/facturas";
import ModalNumFactura from "components/widgets/Modals/ModalNumFactura";
import { Title } from "components/widgets/Texts";
import EditButton from "components/widgets/Buttons/EditButton";
import { CheckButton } from "components/widgets/Buttons/EditButton";
import { useHistory } from "react-router-dom";
import { postNotaCredito } from "api/controllers/facturas";
import ModalDebito from "components/widgets/Modals/ModalDebito";
import ConfimacionModal from "components/widgets/Modals/ConfimacionModal";
const ModalFacturacion = ({
  study,
  setArchived,
  handleArchivarConfirmFacts,
  setShowModalG,
  setShowModalConfirmacdion,
  setAbonarSend,
  abonarSend,
  setEnablefactModalDetails,
}) => {
  const history = useHistory();

  const {
    getFacturasDetails,
    facturasDetail,
    itemOrden,
    itemOrden2,
    loadingDetailFact,
    setloadingStudy,
    setFacturasDetail,
    loadingStudy,
    idItem,
    idItem2,
  } = useFacturaDetail({ studyId: study.id });
  const [studyDetail, setStudyDetail] = useState(null);
  const [studyDetail2, setStudyDetail2] = useState(null);
  const [editing, setEditing] = useState(true);
  const [editing2, setEditing2] = useState(true);
  const [pdfContent, setPdfContent] = useState(null);
  const [pdfContentFact, setPdfContentFact] = useState(null);
  const [pdfContentNotaPago, setPdfContentNotaPago] = useState(null);
  const [openModalFact, setOpenModalFact] = useState(false);
  const [openModalFact2, setOpenModalFact2] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const { factClientTerceros, setfactClientTerceros } = useContext(MainContext);
  const [finishFactTerceros, setFinishFactTerceros] = useState(false);
  const [add2Success, setAdd2Success] = useState(false);
  const [showModalDebito, setShowModalDebito] = useState(false);
  const [showModalCredito, setShowModalCredito] = useState(false);
  const [pdfContentDebito, setPdfContentDebito] = useState(false);
  const [isOpenDebito, setOpenDebito] = useState(false);
  const [isOpenCredito, setOpenCredito] = useState(false);
  const [pdfContentCredito, setPdfContentCredito] = useState(false);
  const [precio, setPrecio] = useState(false);
  const [pagoId, setPagoId] = useState();
  const [data, setData] = useState({
    monto_usd: "",
  });
  const [data2, setData2] = useState({
    monto_usd: "",
  });
  const getStudyDetail = async () => {
    try {
      if (itemOrden) {
        const study = await getStudiesDetail(itemOrden);
        setStudyDetail(study);
      }
      if (itemOrden2) {
        const study = await getStudiesDetail(itemOrden2);
        setStudyDetail2(study);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setloadingStudy(false); facturasDetail
    }
  };
  useEffect(() => {
    if (setAbonarSend) {
      setAbonarSend(false);
      getFacturasDetails();
      return () => {
        setAbonarSend(false);
      };
    }
  }, []);
  useEffect(() => {
    getFacturasDetails();
  }, []);
  useEffect(() => {
    if (abonarSend) {
      getFacturasDetails();
    }
    return () => {
      setAbonarSend(false);
    };
  }, [abonarSend]);
  //
  useEffect(() => {
    getStudyDetail();
    return () => {};
  }, [itemOrden, itemOrden2]);
  useEffect(() => {
    getStudyDetail();
    return () => {
      setAbonarSend(false);
    };
  }, [abonarSend]);

  //console.log(study);

  useEffect(() => {
    const changeClientByOrder = async () => {
      if (study) {
        if (study.pagada === false) {
          const res = await putChangeIdOrdenClient(study.id, {
            cliente_id: study?.cliente?.id,
          });
          // console.log(res);
        }
      }
    };
    changeClientByOrder();
    return () => {};
  }, [finishFactTerceros]);

  const notaCredito = async () => {
    // try {
    //     const postCredito = await postNotaCredito(facturasDetail?.id);
    //     // console.log(postCredito);
    //     if (postCredito) {
    //         setOpenCredito(true);
    //         setPdfContentCredito(postCredito);
    //         toast.success("¡La nota de credito fue generada correctamente!", {
    //             autoClose: 1000,
    //         });
    //     } else {
    //         toast.error("¡Hubo un error al acreditar la factura!", {
    //             autoClose: 1000,
    //         })
    //     }
    // } catch (error) {
    //     console.log(error);
    // }
  };

  const confirmar = async () => {
    try {
      const confirmarFactura = await postConfirmar(facturasDetail?.id);
      if (confirmarFactura) {
        toast.success("¡Se confirmo la factura correctamente!", {
          autoClose: 1000,
        });
        //
        history.push("/admin/Facturacion");
        window.location.reload();
      } else {
        toast.error("¡Hubo un error al confirmar la factura!", {
          autoClose: 1000,
        });
      }
      history.push("/admin/Facturacion");
      window.location.reload();
      //setEnablefactModalDetails(false)
    } catch (error) {
      console.log(error);
    }
  };
  const aggMonto = async () => {
    const id = facturasDetail?.items_orden[1]?.id
      ? facturasDetail?.items_orden[1]?.id
      : facturasDetail?.items_orden[0]?.id;
    try {
      const putEnviarMonto = await putMonto(idItem, data);
      // console.log(putEnviarMonto, 'hola');
      if (putEnviarMonto) {
        // setAdd2Success(false)
        setShowModalConfirmacdion(true);
        toast.success("¡Se envio el monto correctamente!", {
          autoClose: 1000,
        });
        getFacturasDetails();
        // setAdd2Success(true)
        // setShowModalConfirmacdion(true)
      } else {
        toast.error("¡Hubo un error al crear el monto!", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(add2Success, 'estado');
  // console.log(facturasDetail);
  const aggMonto2 = async () => {
    try {
      const putEnviarMonto = await putMonto(
        idItem2,
        data2
      );
      if (putEnviarMonto) {
        toast.success("¡Se envió el monto correctamente!", { autoClose: 1000 });
        getFacturasDetails();
        // setShowModalConfirmacion(true);
      } else {
        toast.error("¡Hubo un error al crear el monto!", { autoClose: 1000 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(add2Success, "estado");
  useEffect(() => {
    if (
      facturasDetail?.items_orden[1]?.monto_usd !== "0.00" &&
      facturasDetail?.items_orden[0]?.monto_usd !== "0.00"
    ) {
      setAdd2Success(true);
      setPrecio(true);
      // console.log('entro');
    } else {
      setAdd2Success(false); // Aquí se establece en false si el monto es cero
    }
  }, [facturasDetail?.items_orden[1]?.monto_usd]);

  //esta funcion cambia los valores que tienen los inputs
  const cambiarValoresRegistro = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const cambiarValoresRegistro2 = (key, value) => {
    setData2((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditClick = () => {
    setEditing(true);
  };
  const handleEditClick2 = () => {
    setEditing2(true);
  };
  const handlePagoIdChange = (pagoId) => {
    setPagoId(handlePagoIdChange);
  };

  //modal
  const [showModal, setShowModal] = useState(false);
  const [showModalRegisterFact, setShowModalRegisterFact] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const toggleModalConfirmacion = () => {
    setShowModalConfirmacion(!showModalConfirmacion);
  };
  const [showModalAbonar, setShowModalAbonar] = useState(false);

  const fechaHora = facturasDetail?.cliente?.created_at;
  const fecha = fechaHora ? fechaHora.split("T")[0] : "";
  let newId = generateUniqueId();
  // const numeroAleatorio = generarNumeroAleatorio(1, 10000000000);
  const generarFactura = async () => {
    setShowModalRegisterFact(true);
  };
  const generarRecibo = async () => {
    console.log(studyDetail, 'datos que se deben mostrar');
    let tiposDeMuestra = studyDetail?.muestras?.map(muestra => muestra.tipo_de_muestra);
    let tiposDeMuestra2 = studyDetail2?.muestras?.map(muestra => muestra.tipo_de_muestra);
    console.log(tiposDeMuestra, 'asi se debe enviar',tiposDeMuestra2,'otro');
    const fact = {
      n_factura: newId,
      muestras: {
        [`${studyDetail?.tipo}`]: tiposDeMuestra?.length,
        [`${studyDetail2?.tipo}`]: tiposDeMuestra2?.length
      }
    };
    const resRecibo = await postRecibo(study.id, fact);
    if (resRecibo) {
      setPdfContent(resRecibo.uri);
      setOpenModalFact(true);
    } else {
      toast.error("¡Ocurrio un error al generar el recibo!", {
        autoClose: 1000,
      });
    }
  };

  const handleArchivar = async () => {
    if (study) {
      const resSendArchived = await postArchivar(study?.id);
      if (resSendArchived) {
        toast.success("¡Se archivo la factura correctamente!", {
          autoClose: 1000,
        });
        handleArchivarConfirmFacts(facturasDetail?.id);
        //setSearchFacturas(informeList.filter((item) => item.completado === true));
        setShowModalG(false);
        setArchived(true);
      } else {
        toast.error("¡Esta factura no se puede archivar!", {
          autoClose: 1000,
        });
      }
    }
    //console.log(resSendArchived)
  };

  useEffect(() => {
    setfactClientTerceros(null);
  }, []);
  // console.log(facturasDetail);
  function Detalles({ studyDetail, studyDetail2, facturasDetail }) {
    const studyDetails = [studyDetail, studyDetail2].filter(Boolean); // Filtrar elementos no definidos o nulos.

    // Ordenar los detalles por ID para mantener el orden constante.
    studyDetails.sort((a, b) => a.estudio - b.estudio);

    const detallesComponent = studyDetails.map(
      (detail, index) =>
        detail && (
          <Box key={detail.id}>
            {/* La sección del paciente se muestra solo una vez, en el primer ítem */}
            {index === 0 && (
              <Box margin={"5px"}>
                <Text fontSize={"16px"}>Paciente</Text>
                <Text fontSize={"14px"}>
                  <Badge>
                    {studyDetails[0]?.paciente.apellidos}{" "}
                    {studyDetails[0]?.paciente.nombres}
                  </Badge>
                </Text>
              </Box>
            )}

            <Box>
              <Grid
                mt={"5px"}
                templateColumns={{
                  lg: "repeat(3,1fr)",
                  md: "repeat(3,1fr)",
                  sm: "repeat(2,1fr)",
                }}
              >
                <Box>
                  <Box margin={"5px"}>
                    <Text fontSize={"16px"}>Estudio # {index + 1}</Text>
                    <Text fontSize={"14px"}>
                      <Badge>{detail.codigo}</Badge>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Box margin={"5px"}>
                    <Text fontSize={"16px"}>Tipo de estudio</Text>
                    <Text fontSize={"14px"}>
                      <Badge>{detail.tipo}</Badge>
                    </Text>
                    <Box width={"50%"}>
                      {facturasDetail &&
                        facturasDetail.balance &&
                        facturasDetail.balance.total_usd !== 0 && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {index === 0 ? (
                              <>
                                <Input
                                  h={"60%"}
                                  type="number"
                                  style={{ marginRight: "8px" }}
                                  value={data?.monto_usd}
                                  onChange={(e) =>
                                    cambiarValoresRegistro(
                                      "monto_usd",
                                      e.target.value
                                    )
                                  }
                                />
                                <CheckButton handleClick={aggMonto} />
                              </>
                            ) : null}
                            {index === 1 ? (
                              <>
                                <Input
                                  h={"60%"}
                                  type="number"
                                  style={{ marginRight: "8px" }}
                                  value={data2?.monto_usd}
                                  onChange={(e) =>
                                    cambiarValoresRegistro2(
                                      "monto_usd",
                                      e.target.value
                                    )
                                  }
                                />
                                <CheckButton handleClick={aggMonto2} />
                              </>
                            ) : null}
                          </div>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box margin={"5px"}>
                    <Text fontSize={"16px"}>Muestras</Text>
                    <Select style={{ height: "30px" }}>
                      {detail.muestras
                        ?.slice()
                        .reverse()
                        .map((muestra, muestraIndex) => (
                          <option
                            key={muestraIndex}
                            value={muestra.tipo_de_muestra}
                          >
                            {muestra.tipo_de_muestra}
                          </option>
                        ))}
                    </Select>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        )
    );

    return <>{detallesComponent}</>;
  }

  return (
    <>
      {loadingDetailFact ? (
        <div className="centerLoader">
          <CircularProgress value={80} size="80px" color="#137797" />
        </div>
      ) : (
        <Box marginTop={"-50px"}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            padding={"5px"}
          >
            <Title title={"Descripción"} />
            <Text color={"gray.500"} fontSize={"17px"} mr={"20px"}>
              Número de Orden {facturasDetail && facturasDetail?.id}
            </Text>
          </Box>
          {/* {studyDetail && (
            <>
              <Box>
                <Box margin={"5px"}>
                  <Text fontSize={"16px"}>Paciente</Text>
                  {studyDetail ? (
                    <Text fontSize={"14px"}>
                      <Badge>
                        {studyDetail?.paciente.apellidos}{" "}
                        {studyDetail?.paciente.nombres}
                      </Badge>
                    </Text>
                  ) : (
                    <Text fontSize={"14px"}>Loading...</Text>
                  )}
                </Box>
              </Box>
              <Grid
                mt={"5px"}
                templateColumns={{
                  lg: "repeat(3,1fr)",
                  md: "repeat(3,1fr)",
                  sm: "repeat(2,1fr)",
                }}
              >
                <Box>
                  <Box margin={"5px"}>
                    <Text fontSize={"16px"}> Estudio # 1</Text>
                    {studyDetail ? (
                      <Text fontSize={"14px"}>
                        <Badge>{studyDetail?.codigo}</Badge>
                      </Text>
                    ) : (
                      <Text fontSize={"14px"}>Loading...</Text>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box margin={" 5px "}>
                    <Text fontSize={"16px"}>Tipo de estudio</Text>
                    {studyDetail ? (
                      <>
                        <Text fontSize={"14px"}>
                          <Badge>{studyDetail.tipo}</Badge>
                        </Text>
                        <Box width={"50%"}>
                          {facturasDetail.balance.total_usd !== 0 ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Input
                                h={"60%"}
                                type="number"
                                style={{ marginRight: "8px" }}
                                value={data?.monto_usd}
                                onChange={(e) =>
                                  cambiarValoresRegistro(
                                    "monto_usd",
                                    e.target.value
                                  )
                                }
                              />
                              <CheckButton handleClick={aggMonto} />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Text fontSize={"14px"}>Loading...</Text>
                    )}
                  </Box>
                </Box>

                {
                  <Box>
                    <Box margin={" 5px "}>
                      <Text fontSize={"16px"}>Muestras</Text>
                      {studyDetail ? (
                        <Select style={{ height: "30px" }}>
                          {studyDetail?.muestras
                            ?.slice()
                            .reverse()
                            .map((muestra, index) => (
                              <option
                                key={index}
                                value={muestra.tipo_de_muestra}
                              >
                                {muestra.tipo_de_muestra}
                              </option>
                            ))}
                        </Select>
                      ) : (
                        <Text fontSize={"14px"}>Loading...</Text>
                      )}
                    </Box>
                  </Box>
                }
                <Box></Box>
              </Grid>
            </>
          )}
          {studyDetail2 && (
            <Grid
              mt={"5px"}
              templateColumns={{
                lg: "repeat(3,1fr)",
                md: "repeat(3,1fr)",
                sm: "repeat(2,1fr)",
              }}
            >
              <Box>
                <Box margin={"5px"}>
                  <Text fontSize={"16px"}> Estudio # 2</Text>
                  {studyDetail2 ? (
                    <Text fontSize={"14px"}>
                      <Badge>{studyDetail2?.codigo}</Badge>
                    </Text>
                  ) : (
                    <Text fontSize={"14px"}>Loading...</Text>
                  )}
                </Box>
              </Box>

              <Box>
                <Box margin={" 5px "}>
                  <Text fontSize={"16px"}>Tipo de estudio </Text>
                  {studyDetail2 ? (
                    <>
                      <Text fontSize={"14px"}>
                        <Badge>{studyDetail2.tipo}</Badge>
                      </Text>
                      <Box width={"50%"}>
                        {facturasDetail.balance.total_usd !== 0 ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Input
                              h={"60%"}
                              type="number"
                              style={{ marginRight: "8px" }}
                              value={data2?.monto_usd}
                              onChange={(e) =>
                                cambiarValoresRegistro2(
                                  "monto_usd",
                                  e.target.value
                                )
                              }
                            />
                            <CheckButton handleClick={aggMonto2} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Text fontSize={"14px"}>Loading...</Text>
                  )}
                </Box>
              </Box>

              {
                <Box>
                  <Box margin={" 5px "}>
                    <Text fontSize={"16px"}>Muestras </Text>
                    {studyDetail2 ? (
                      <Select style={{ height: "30px" }}>
                        {studyDetail2?.muestras.map((muestra, index) => (
                          <option key={index} value={muestra.tipo_de_muestra}>
                            {muestra.tipo_de_muestra}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Text fontSize={"14px"}>Loading...</Text>
                    )}
                  </Box>
                </Box>
              }

              <Box></Box>
            </Grid>
          )} */}
          <Detalles
            studyDetail={studyDetail}
            studyDetail2={studyDetail2}
            facturasDetail={facturasDetail}
          />
          <Separator></Separator>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            padding={"5px"}
          >
            <Title title={"Datos de factura"} />
          </Box>
          <Grid
            templateColumns={{
              lg: "repeat(5,1fr)",
              md: "repeat(3,1fr)",
              sm: "repeat(2,1fr)",
            }}
          >
            <Box margin={"5px"}>
              <Box margin={"5px"}>
                <Text fontSize={"16px"}>Cliente</Text>
                {facturasDetail && !factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>
                      {facturasDetail?.cliente.razon_social?.length > 17
                        ? facturasDetail.cliente?.razon_social?.substring(
                            0,
                            17
                          ) + "..."
                        : facturasDetail.cliente?.razon_social}
                    </Badge>
                  </Text>
                ) : factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Text fontSize={"14px"}>
                      <Badge>
                        {factClientTerceros?.razon_social?.length > 17
                          ? factClientTerceros?.razon_social?.substring(0, 17) +
                            "..."
                          : factClientTerceros?.razon_social}
                      </Badge>
                    </Text>
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Box>
              <Box margin={"5px"}>
                <Text fontSize={"16px"}>RIF/CI</Text>
                {facturasDetail && !factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{facturasDetail.cliente.ci_rif}</Badge>
                  </Text>
                ) : factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Text fontSize={"14px"}>
                      <Badge>{factClientTerceros?.ci_rif}</Badge>
                    </Text>
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Box>
              <Box margin={"5px"}>
                <Text fontSize={"16px"}>Fecha</Text>
                {facturasDetail && !factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{formatDate(facturasDetail.created_at)}</Badge>
                  </Text>
                ) : factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Text fontSize={"14px"}>
                      <Badge>{formatDate(factClientTerceros.created_at)}</Badge>
                    </Text>
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Box>
              <Box margin={"5px"}>
                <Text fontSize={"16px"}>Télefono</Text>
                {facturasDetail && !factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{facturasDetail.cliente.telefono_celular}</Badge>
                  </Text>
                ) : factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{factClientTerceros.telefono_celular}</Badge>
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Box>
              <Box margin={"5px"}>
                {<Text fontSize={"16px"}>Dirección</Text>}
                {facturasDetail && !factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{facturasDetail.cliente.direccion}</Badge>
                  </Text>
                ) : factClientTerceros ? (
                  <Text fontSize={"14px"}>
                    <Badge>{factClientTerceros.direccion}</Badge>
                  </Text>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Button
            marginTop={"15px"}
            marginBottom={"10px"}
            // marginLeft={{ lg: '70%', md: '60%', sm: '40%' }}
            borderRadius={"20px"}
            bgColor={"#137797"}
            color="#ffff"
            onClick={() => toggleModal(study)}
          >
            Factura para un tercero
          </Button>
          <Separator mb={"15px"}></Separator>
          <Grid
            marginTop={"5px"}
            marginBottom={"3px"}
            marginLeft={"5px"}
            marginRight={"18px"}
            templateColumns={{
              lg: "repeat(5,1fr)",
              md: "repeat(3,1fr)",
              sm: "repeat(2,1fr)",
            }}
          >
            <Box margin={"5px"}>
              <Text margin={"5px"} fontSize={"16px"}>
                Estado
              </Text>
              {facturasDetail ? (
                <Text fontSize={"14px"}>
                  {facturasDetail.pagada ? (
                    <Badge variant="subtle" colorScheme="green">
                      Completado
                    </Badge>
                  ) : (
                    <Badge variant="subtle" colorScheme={"orange"}>
                      Pendiente
                    </Badge>
                  )}
                </Text>
              ) : (
                <Text fontSize={"14px"}>Loading...</Text>
              )}
            </Box>
            <Box>
              <Box marginTop={"5px"} display={"flex"} flexDirection={"column"}>
                <Text margin={"5px"} fontSize={"16px"}>
                  {studyDetail2 ? "Monto " : "Monto"}
                </Text>
                {facturasDetail ? (
                  facturasDetail.balance.total_usd !== 0 ? (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Text fontSize={"14px"}>
                        <Badge>Dolares ($)</Badge>
                      </Text>
                      <Text fontSize={"14px"} marginTop={"5px"}>
                        <Badge>Bolivares (Bs)</Badge>
                      </Text>
                    </Box>
                  ) : (
                    <>
                      {editing ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Input
                            h={"60%"}
                            type="number"
                            style={{ marginRight: "8px" }}
                            value={data?.monto_usd}
                            onChange={(e) =>
                              cambiarValoresRegistro(
                                "monto_usd",
                                e.target.value
                              )
                            }
                          />
                          <CheckButton handleClick={aggMonto} />
                        </div>
                      ) : (
                        <EditButton handleClick={handleEditClick} />
                      )}
                    </>
                  )
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
                {add2Success ? (
                  <></>
                ) : (
                  <Text margin={"5px"} textAlign={"left"} fontSize={"16px"}>
                    {studyDetail2 ? "Monto 2" : " "}
                  </Text>
                )}
                {studyDetail2 && (
                  <>
                    {editing2 ? (
                      <Box
                        display={add2Success ? "none" : "flex"}
                        alignItems={"left"}
                      >
                        <Input
                          h={"60%"}
                          type="number"
                          style={{ marginRight: "8px" }}
                          value={data2?.monto_usd}
                          onChange={(e) =>
                            cambiarValoresRegistro2("monto_usd", e.target.value)
                          }
                        />
                        <CheckButton handleClick={aggMonto2} />
                      </Box>
                    ) : (
                      <></>
                      // <EditButton handleClick={handleEditClick2} />
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <Box margin={"10px"}>
                <Text margin={"5px"} fontSize={"16px"}>
                  Pendiente
                </Text>
                {facturasDetail ? (
                  <Text fontSize={"14px"} marginTop={"5px"}>
                    <Badge>{facturasDetail.balance.por_pagar_usd} $</Badge>
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
                {facturasDetail ? (
                  <Text fontSize={"14px"} marginTop={"5px"}>
                    <Badge>{facturasDetail.balance.por_pagar_bs} Bs</Badge>
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
              </Box>
            </Box>
            <Box>
              <Box margin={"10px"}>
                <Text margin={"5px"} fontSize={"16px"}>
                  Abonado
                </Text>
                {facturasDetail ? (
                  <Text fontSize={"14px"} marginTop={"5px"}>
                    <Badge>{facturasDetail.balance?.pagado_usd} $</Badge>
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
                {facturasDetail ? (
                  <Text fontSize={"14px"} marginTop={"5px"}>
                    <Badge>{facturasDetail.balance?.pagado_bs} Bs</Badge>
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
              </Box>
            </Box>
            <Box>
              <Box margin={"10px"}>
                <Text margin={"5px"} fontSize={"16px"}>
                  Total
                </Text>
                {facturasDetail ? (
                  <Text
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Badge>{facturasDetail.balance?.total_usd} $</Badge>
                    {facturasDetail.pagada}
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}

                {facturasDetail ? (
                  <Text fontSize={"14px"}>
                    <Badge>{facturasDetail.balance?.total_bs} Bs</Badge>
                  </Text>
                ) : (
                  <Text fontSize={"14px"}>Loading...</Text>
                )}
              </Box>
            </Box>
          </Grid>
          {facturasDetail && facturasDetail.pagada ? (
            <Box>
              <GeneralButton
                text="Generar recibo"
                handleClick={generarRecibo}
              />
              <GeneralButton
                text="Generar factura"
                handleClick={generarFactura}
              />
              <GeneralButton
                text="Nota de débito"
                handleClick={() => setShowModalDebito(!showModalDebito)}
              />
              <GeneralButton
                text="Nota de credito"
                handleClick={() => setShowModalCredito(!showModalCredito)}
              />
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                //disabled={facturasDetail?.confirmada === false ? true : false}
                onClick={handleArchivar}
                //marginBottom={{ lg: '-10%', md: '-13%', sm: '-25%' }}
                marginRight={"2%"}
                borderRadius={"20px"}
                bgColor={"#137797"}
                color="#ffff"
              >
                Archivar
              </Button>
              {
                <Button
                  disabled={
                    facturasDetail?.confirmada === false ||
                    facturasDetail?.pagada === true
                      ? true
                      : false
                  }
                  borderRadius={"20px"}
                  bgColor={"#137797"}
                  color="#ffff"
                  onClick={() => setShowModalAbonar(true)}
                >
                  Abonar
                </Button>
              }

              {facturasDetail?.confirmada === false ? (
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {
                    <GeneralButton
                      text="Confirmar"
                      handleClick={confirmar}
                      // disabled={facturasDetail?.balance.pagado_usd
                      //  === 0 ? true : false}
                    />
                  }
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <ModalPrint
            text={"¿Desea descargar el recibo ?"}
            isOpen={openModalFact}
            setOpenModal={setOpenModalFact}
            pdfContent={pdfContent}
          />
          {/* <ModalPrint text={'¿Desea descargar la factura ?'} isOpen={openModalFact2} setOpenModal={setOpenModalFact2} pdfContent={pdfContentFact} />*/}
          <ModalPrint
            text={"¿Desea descargar la nota de pago ?"}
            isOpen={openModalPago}
            setOpenModal={setOpenModalPago}
            pdfContent={pdfContentNotaPago}
            type={"nota"}
          />
          <ModalPrint
            text={"¿Desea descargar la nota de debito?"}
            isOpen={isOpenDebito}
            setOpenModal={setOpenDebito}
            pdfContent={pdfContentDebito}
          />
          <ModalPrint
            text={"¿Desea descargar la nota de credito?"}
            isOpen={isOpenCredito}
            setOpenModal={setOpenCredito}
            pdfContent={pdfContentCredito}
          />
        </Box>
      )}

      <ModalFctTerceros
        setFinishFactTerceros={setFinishFactTerceros}
        study={study}
        toggleModal={toggleModal}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <Modal
        size={"xs"}
        maxWidth="100%"
        isOpen={showModalConfirmacion}
        onClose={toggleModalConfirmacion}
      >
        <ModalOverlay />
        <ModalContent marginTop={"15%"} bg="#ffff" borderRadius={"20px"}>
          <ModalBody>
            <Confirmacion
              pago={pagoId}
              facturasDetail={facturasDetail}
              toggleModalConfirmacion={toggleModalConfirmacion}
              confirmar={confirmar}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <ModalNumFactura
        study={study}
        setPdfContentFact={setPdfContentFact}
        setShowModal={setShowModalRegisterFact}
        isOpen={showModalRegisterFact}
        pdfContentFact={pdfContentFact}
        setOpenModalFact2={setOpenModalFact2}
        openModalFact2={openModalFact2}
        studyDetail={studyDetail}
        studyDetail2={studyDetail2}
      />

      <AddAbonarModal
        setAbonarSend={setAbonarSend}
        openModalPago={openModalPago}
        setOpenModalPago={setOpenModalPago}
        facturasDetail={facturasDetail}
        isOpen={showModalAbonar}
        setShowModal={setShowModalAbonar}
        idOrden={facturasDetail?.id}
        setPdfContent={setPdfContentNotaPago}
      />
      <ModalDebito
        setAbonarSend={setAbonarSend}
        openModal={isOpenDebito}
        setOpenModal={setOpenDebito}
        facturasDetail={facturasDetail}
        isOpen={showModalDebito}
        setShowModal={setShowModalDebito}
        idOrden={facturasDetail?.id}
        setPdfContent={setPdfContentDebito}
        titulo={"Monto de la nota de debito"}
      />
      <ConfimacionModal
        setAbonarSend={setAbonarSend}
        openModal={isOpenDebito}
        setOpenModal={setOpenCredito}
        facturasDetail={facturasDetail}
        isOpen={showModalCredito}
        setShowModal={setShowModalCredito}
        idOrden={facturasDetail?.id}
        setPdfContent={setPdfContentCredito}
        titulo={"Monto de la nota de debito"}
      />
    </>
  );
};
export default ModalFacturacion;

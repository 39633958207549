import { studiesDetail } from "api/controllers/estudios";
import { getFacturasDetail } from "api/controllers/facturas";
import { getFacturasList } from "api/controllers/facturas";
import { getCambio } from "api/controllers/tazaDia";
import MainContext from "context/mainContext/MainContext";
import { useContext } from "react";
import { useCallback, useMemo, useState } from "react";

export function useFacturaDetail({ studyId }) {
  const [facturasDetail, setFacturasDetail] = useState(null);
  const [studyDetail, setStudyDetail] = useState(null);
  const [itemOrden, setItemOrden] = useState(null);
  const [itemOrden2, setItemOrden2] = useState(null);
  const [loadingDetailFact, setloadingDetailFact] = useState(false);
  const [errorC, seterrorC] = useState(false);
  const [loadingStudy, setloadingStudy] = useState(false);
  const [error, seterror] = useState(false);
  const [idItem, setIdItem] = useState(null);
  const [idItem2, setIdItem2] = useState(null);
  const getFacturasDetails = useCallback(async () => {
    try {
      setloadingDetailFact(true);
      seterror(false);
      const facturasDetail = await getFacturasDetail(studyId);
      setFacturasDetail(facturasDetail);

      // Actualizar archived a true si por_pagar_usd es igual a 0
      if (facturasDetail.por_pagar_usd === 0) {
        setFacturasDetail((prevState) => ({
          ...prevState,
          archived: true,
        }));
      }

      const itemsOrden = facturasDetail.items_orden;

      let mayorEstudio = null;
      let segundoMayorEstudio = null;
      let idMayorEstudio = null;
      let idSegundoMayorEstudio = null;

      // Iterar sobre cada item para determinar el mayor y el segundo mayor estudio
      itemsOrden.forEach((item) => {
        if (!mayorEstudio || item.estudio > mayorEstudio) {
          // Actualizar el segundo mayor antes de actualizar el mayor
          segundoMayorEstudio = mayorEstudio;
          idSegundoMayorEstudio = idMayorEstudio;

          mayorEstudio = item.estudio;
          idMayorEstudio = item.id;
        } else if (!segundoMayorEstudio || item.estudio > segundoMayorEstudio) {
          segundoMayorEstudio = item.estudio;
          idSegundoMayorEstudio = item.id;
        }
      });

      // Asignar los estudios y IDs a sus respectivos estados
      if (mayorEstudio !== null) {
        setItemOrden(mayorEstudio);
        setIdItem(idMayorEstudio);
      }
      if (segundoMayorEstudio !== null) {
        setItemOrden2(segundoMayorEstudio);
        setIdItem2(idSegundoMayorEstudio);
      }
      // if (itemsOrden && itemsOrden.length > 0) {
      //   setItemOrden(itemsOrden[0].estudio);
      // }
      // if (itemsOrden && itemsOrden.length > 1) {
      //   //setItemOrden(itemsOrden[0].estudio);
      //   setItemOrden2(itemsOrden[1].estudio);
      // }

      //setItemOrden2()
    } catch (error) {
      console.log(error);
      seterror(true);
    } finally {
      setloadingDetailFact(false);
    }
  }, [studyId]);

  const getStudyDetail = useCallback(async () => {
    try {
      setloadingStudy(true);
      seterror(false);
      const study = await studiesDetail(24);
      setStudyDetail(study);
    } catch (error) {
      console.log(error);
      seterror(true);
    } finally {
      setloadingStudy(false);
    }
  }, []);
  console.log(idItem,'id del item');
  return {
    getFacturasDetails,
    facturasDetail,
    studyDetail,
    itemOrden,
    getStudyDetail,
    loadingDetailFact,
    loadingStudy,
    itemOrden,
    itemOrden2,
    setFacturasDetail,
    idItem,
    idItem2
  };
}
